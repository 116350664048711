import React from "react";
import GContainer from "../components/GContainer";
import GLink from "../components/GLink";
import { Typography, Link, Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";

//last
import Box from "@material-ui/core/Box";

const GHeader = ({ children }) => {
  return (
    <Box pb={7}>
      <Typography variant="h2">{children}</Typography>
    </Box>
  );
};

function GQuestion(props) {
  return (
    <Box pb={2}>
      <Typography variant="h6">{props.children}</Typography>
    </Box>
  );
}

function GAnswer(props) {
  return (
    <Box pb={8}>
      <Typography variant="body1">{props.children}</Typography>
    </Box>
  );
}

const GDivider = () => (
  <Box pt={6} pb="3rem">
    <Divider />
  </Box>
);

export default function App() {
  return (
    <>
      <Helmet>
        <title>XVision - Support</title>
      </Helmet>

      <Box pt="4rem" pb="6rem" bgcolor="#F7F7F7">
        <GContainer maxW="md">
          <Box pb={2}>
            <Typography variant="h1" align="center" paragraph>
              We&apos;re Here.
            </Typography>
          </Box>
          <Typography variant="h5" align="center" paragraph>
            Feel free to email us if you need help or have any feedback. We love to hear from you,
            unless you&apos;re a spambot :)
          </Typography>
          <Box textAlign="center">
            <Link noWrap variant="h5" color="secondary" href="mailto:support@xvision.me">
              support@xvision.me
            </Link>
          </Box>
        </GContainer>
      </Box>

      <Box pt="4rem">
        <GContainer>
          <GHeader>DataMan</GHeader>
          <GQuestion>What&apos;s Smart Forecast? How can it help me?</GQuestion>
          <GAnswer>
            Smart Forecast in DataMan predicts if you&apos;ll stay within your data cap. It uses
            on-device intelligence to compute real-time usage forecasts based on multiple factors
            including your current usage and the remaining days in your bill cycle. This helps you
            plan ahead and take precautions.
            <br />
            <br />
            For example, you&apos;ve used 95% of your cap. If there&apos;s only one day left in your
            cycle, the forecast is SAFE. This means you&apos;ve enough to last till the end of your
            cycle. But if there&apos;re 29 days left, the forecast is DANGER and you should slow
            down your usage now to prevent overage at the end.
          </GAnswer>

          <GQuestion>Can I track more than one data plan?</GQuestion>
          <GAnswer>
            DataMan lets you manage multiple plans. You can set up multiple data plan profiles, each
            with its own plan details and usage data. This advanced feature is brilliant for anyone
            who has more than one data plan, separate data caps for different times, or
            international travel roaming plans.
          </GAnswer>

          <GQuestion>Can I use DataMan in the Shortcuts app?</GQuestion>
          <GAnswer>
            DataMan lets you use your data usage statistics in Shortcuts. You can automate tasks,
            like turning cellular data off, based on your forecast. In your custom shortcut, add the
            DataMan action to use its outputs.{" "}
            <GLink url="https://www.icloud.com/shortcuts/8372067a2613479e9d797787fc224156">
              View sample shortcut
            </GLink>
          </GAnswer>

          <GQuestion>How do I use DataMan Complication for Apple Watch?</GQuestion>
          <GAnswer>
            DataMan Complication shows your Smart Forecast icon. So a tick icon means SAFE and
            you&apos;re not at risk of overage. Now you can just raise your wrist and know if
            you&apos;re safe from busting your data cap.
          </GAnswer>

          <GQuestion>
            I like to deploy DataMan in my business. Does it support managed app configuration?
          </GQuestion>
          <GAnswer>
            We&apos;ve a custom version designed for easy deployment using Apple Business Manager.
            It supports MDM and managed app configuration. Talk to us to learn how DataMan can help
            employees avoid overage.{" "}
            <GLink newTab url="mailto:support@xvision.me?subject=DataMan for Business">
              Email Support
            </GLink>
          </GAnswer>

          <GQuestion>Does DataMan use VPN? Is it secure? Is it private?</GQuestion>
          <GAnswer>
            DataMan does not use VPN. DataMan computes the statistics locally on your device. It
            doesn&apos;t reroute any data through proxy/VPN or change your configuration profile.
            And it doesn&apos;t monetize your info. So DataMan is secure and private.
          </GAnswer>

          <GQuestion>Does DataMan drain my battery?</GQuestion>
          <GAnswer>
            No. DataMan is designed to work with iOS to be extremely power efficient.
          </GAnswer>

          <GQuestion>What&apos;re the touch gestures in DataMan?</GQuestion>
          <GAnswer>
            On the main screen, swipe up to see more statistics. Swipe left to access Settings. On
            the Day screen, swipe left/right to switch days.
          </GAnswer>

          <GQuestion>How do I set up DataMan to support my 30-day (or N-day) plan?</GQuestion>
          <GAnswer>
            On the main screen, swipe left to access Settings &gt; Data Plan &gt; Plan Type &gt; N
            Days. DataMan supports all billing periods.
          </GAnswer>

          <GQuestion>How do I use the Stopwatch widget?</GQuestion>
          <GAnswer>
            The Stopwatch widget helps you track any activity and see usage for a specific period of
            time. Tap the green button to start tracking. Tap again to stop. Tap the text to show
            more details.
          </GAnswer>

          <GQuestion>Can I remove DataMan from iOS multitasking screen?</GQuestion>
          <GAnswer>
            No. You should keep DataMan in the multitasking screen to allow background app refresh.
            DataMan refreshes your usage statistics in the background. So turn on Background App
            Refresh for DataMan in iOS Settings &gt; General.
          </GAnswer>

          <GQuestion>
            I want to restart (or power off) iPhone/iPad. Will DataMan be affected?
          </GQuestion>
          <GAnswer>
            If Background App Refresh for DataMan is turned on, there&apos;s nothing for you to do.
            Otherwise, turn on Background App Refresh for DataMan to ensure your usage is properly
            recorded.
          </GAnswer>

          <GQuestion>Does DataMan include Wi-Fi usage in my data allowance?</GQuestion>
          <GAnswer>
            No, the data cap and alerts are only for cellular usage, not Wi-Fi. DataMan assumes your
            Wi-Fi is unlimited.
            <br />
            <br />
            If you like to track your Wi-Fi usage across multiple networks, you can consider
            WifiMan.{" "}
            <GLink url="https://itunes.apple.com/us/app/id1054634018?mt=8&at=10l9Ek">
              Learn more
            </GLink>
          </GAnswer>

          <GDivider />

          <GHeader>WifiMan</GHeader>
          <GQuestion>Is there any promotion for WifiMan?</GQuestion>
          <GAnswer>
            You can buy our DataMan + WifiMan bundle and get DataMan and WifiMan together at a
            reduced price.{" "}
            <GLink url="https://itunes.apple.com/us/app-bundle/id1058734370?mt=8&at=10l9Ek">
              View app bundle
            </GLink>
          </GAnswer>

          <GQuestion>I already have DataMan. Can I get WifiMan at a discount?</GQuestion>
          <GAnswer>
            Yes, you can use Complete My Bundle when you buy our DataMan + WifiMan bundle. When you
            complete the bundle, you won&apos;t have to pay the full bundled price.
          </GAnswer>

          <GDivider />

          <GHeader>Others</GHeader>
          <GQuestion>How can I follow your news and updates?</GQuestion>
          <GAnswer>
            Follow us on Twitter @XVisionNow. Or like our Facebook Page. We share regularly about
            our new apps, updates, sales, etc.
          </GAnswer>

          <GQuestion>Where&apos;s the support for Palm applications?</GQuestion>
          <GAnswer>
            Not to worry. Just email us and we&apos;ll be glad to help. It&apos;s amazing to see
            people still using Palm :)
          </GAnswer>
        </GContainer>
      </Box>
    </>
  );
}
